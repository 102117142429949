import { initializeApp } from "firebase/app";
import { addDoc, collection, deleteDoc, getDocs, getFirestore, doc } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { useEffect, useState } from "react";

const firebaseApp = initializeApp({
  apiKey: "AIzaSyDcR_NpDfqe9bayAzRYzoIb5PPlnvBRKf0",
  authDomain: "fir-react-b1b87.firebaseapp.com",
  projectId: "fir-react-b1b87",
});

export const App = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [userList, setUserList] = useState([]);

  const db = getFirestore(firebaseApp);
  const userCollectionRef = collection(db, "user");

  async function createUser(){
    const user = await addDoc(userCollectionRef, {
      name,
      email,
    });
    console.log(user);
  }

  async function deleteUser(id){
    const userDoc = doc(db, "user", id);
     await deleteDoc(userDoc);
  }

  useEffect(() => {
    const getUserList = async () => {
      const data = await getDocs(userCollectionRef);
      console.log(data.docs.map( (doc) => ({ ... doc.data(), id: doc.id}) ));
      setUserList(data.docs.map( (doc) => ({ ... doc.data(), id: doc.id}) ))
    };
    getUserList();
  }, []);

  return (
    <div>
      <input
        type="text"
        placeholder="Nome"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <input
        type="text"
        placeholder="E-mail"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <button onClick={createUser}>Criar usuário</button>
      <ul>
        {userList.map(user => {
          return (
            <div key={user.id}>
              <li>{user.name}</li>
              <li>{user.email}</li>
              <button onClick={() => deleteUser(user.id)}>Deletar</button>
            </div>
          )
        })}
      </ul>
    </div>
  );
};

// export default App;
